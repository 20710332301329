const PanelBasic  = {
	panel: "Análise Básica",
	title: "",
	configurationCase: 1,
	permission: false,
	specialCharts:[],
	leftActions:[
		{
			title:"Painel Esquerdo",
			colors:[
				"#095FA3", "#C20000", "#B7B7B7"
			],
			actions:[
				{
					title:"Ações Ataque",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'donut',
					options:[
						{ title:"Ação Positiva", color: 0, value: 2, hasSlider: true },
						{ title:"Ação Neutra", color: 2, value: 2, hasSlider: true },
						{ title:"Ação Negativa", color: 1, value: 2, hasSlider: true },
					], 
					data:[]
				},{
					title:"Ações Defesa",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'donut',
					options:[
						{ title:"Ação Positiva", color: 0, value: 2, hasSlider: true },
						{ title:"Ação Neutra", color: 2, value: 2, hasSlider: true },
						{ title:"Ação Negativa", color: 1, value: 2, hasSlider: true },
					], 
					data:[]
				}
			]			
		}
	],
	rightActions:[	
		{
			title:"Painel Direito",			
			colors:[
				"#C20000", "#095FA3", "#B7B7B7"
			],
			actions:[
				{
					title:"Ações Ataque",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'donut',
					options:[
						{ title:"Ação Positiva", color: 0, value: 2, hasSlider: true },
						{ title:"Ação Neutra", color: 2, value: 2, hasSlider: true },
						{ title:"Ação Negativa", color: 1, value: 2, hasSlider: true },
					], 
					data:[]
				},{
					title:"Ações Defesa",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'donut',
					options:[
						{ title:"Ação Positiva", color: 0, value: 2, hasSlider: true },
						{ title:"Ação Neutra", color: 2, value: 2, hasSlider: true },
						{ title:"Ação Negativa", color: 1, value: 2, hasSlider: true },
					], 
					data:[]
				}
			]
		}
	]
}

const PanelTelestrator  = {
	panel: "Edição de Vídeo (Estática)",
	title: "",
	configurationCase: 0,
	permission: false,
	specialCharts:[],
	leftActions:[],
	rightActions:[]
}

const PainelCategoriaCanal = {
	panel: "Análise Completa de Partida",
	title: "",
	configurationCase: 1,
	permission: true,
	specialCharts:[
		"pressure"
	],
	leftActions:[
		{
			title:"Mandante",			
			colors:[
				"#095FA3", "#C20000", "#B7B7B7"
			],
			actions:[
				{
					title:"Saída de Bola",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'donut',
					options:[
						{ title:"Sem Pressão na Saída", color: 2, value: 3, hasSlider: false },
						{ title:"Perde Posse (Sem Exposição)", color: 1, value: 3, hasSlider: false },
						{ title:"Perde Posse (Exposto)", color: 1, value: 6, hasSlider: false },
						{ title:"Avança (Adversário Recompõe)", color: 0, value: 3, hasSlider: false },
						{ title:"Ataque (Construção Rápida)", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				},
				{
					title:"Ataque Posicional",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'sankey',
					options:[
						{ title:"Sem Ataque (Posse Perdida)", color: 1, value: 3, hasSlider: false },
						{ title:"Sem Ataque (Recua em Campo)", color: 2, value: 3, hasSlider: false },
						{ title:"Circulação Longa (Posse)", color: 2, value: 3, hasSlider: false },
						{ title:"Chute Longa Distância", color: 0, value: 2, hasSlider: true },
						{ title:"Cruzamento", color: 0, value: 2, hasSlider: true },
						{ title:"Profundidade", color: 0, value: 2, hasSlider: true },
						{ title:"Infiltração", color: 0, value: 2, hasSlider: true },
						{ title:"Inversão", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				},
				{
					title:"Bolas Paradas",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'sankey',
					options:[
						{ title:"Escanteio", color: 0, value: 2, hasSlider: true },
						{ title:"Falta Assistência", color: 0, value: 2, hasSlider: true },
						{ title:"Falta Direta", color: 0, value: 2, hasSlider: true },
						{ title:"Lateral na Área", color: 0, value: 2, hasSlider: true },
						{ title:"Pênalti", color: 0, value: 8, hasSlider: true },
						{ title:"Reposição da Defesa", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				},
				{
					title:"Contra-Ataque",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'sankey',
					options:[
						{ title:"Saída de Bola (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Ataque Posicional (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Construção Rápida (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Bola Parada (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Contra-Ataque (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Perde e Pressiona", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				}
			]			
		}
	],
	rightActions:[	
		{
			title:"Visitante",			
			colors:[
				"#C20000", "#095FA3", "#B7B7B7"
			],
			actions:[
				{
					title:"Saída de Bola",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'donut',
					options:[
						{ title:"Sem Pressão na Saída", color: 2, value: 3, hasSlider: false },
						{ title:"Perde Posse (Sem Exposição)", color: 1, value: 3, hasSlider: false },
						{ title:"Perde Posse (Exposto)", color: 1, value: 6, hasSlider: false },
						{ title:"Avança (Adversário Recompõe)", color: 0, value: 3, hasSlider: false },
						{ title:"Ataque (Construção Rápida)", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				},
				{
					title:"Ataque Posicional",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'sankey',
					options:[
						{ title:"Sem Ataque (Posse Perdida)", color: 1, value: 3, hasSlider: false },
						{ title:"Sem Ataque (Recua em Campo)", color: 2, value: 3, hasSlider: false },						
						{ title:"Circulação Longa (Posse)", color: 2, value: 3, hasSlider: false },
						{ title:"Chute Longa Distância", color: 0, value: 2, hasSlider: true },
						{ title:"Cruzamento", color: 0, value: 2, hasSlider: true },
						{ title:"Profundidade", color: 0, value: 2, hasSlider: true },
						{ title:"Infiltração", color: 0, value: 2, hasSlider: true },
						{ title:"Inversão", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				},
				{
					title:"Bolas Paradas",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'sankey',
					options:[
						{ title:"Escanteio", color: 0, value: 2, hasSlider: true },
						{ title:"Falta Assistência", color: 0, value: 2, hasSlider: true },
						{ title:"Falta Direta", color: 0, value: 2, hasSlider: true },
						{ title:"Lateral na Área", color: 0, value: 2, hasSlider: true },
						{ title:"Pênalti", color: 0, value: 8, hasSlider: true },
						{ title:"Reposição da Defesa", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				},
				{
					title:"Contra-Ataque",
					button:"Adicionar",
					hasOptions:true,
					multipleOptions:false,					
					addOptions: false,
					customBullets: true,
					analytics: 'sankey',
					options:[
						{ title:"Saída de Bola (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Ataque Posicional (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Construção Rápida (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Bola Parada (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Contra-Ataque (Adversário)", color: 0, value: 2, hasSlider: true },
						{ title:"Perde e Pressiona", color: 0, value: 2, hasSlider: true },
					], 
					data:[]
				}
			]
		}
	]
}

const PanelCustom  = {
	panel: "Painel Customizado",
	title: "",
	configurationCase: 2,
	permission: true,
	specialCharts:[],
	leftActions:[
		{
			title:"Esquerda",
			colors:[
				"#095FA3", "#C20000", "#B7B7B7"
			],
			actions:[]		
		}
	],
	rightActions:[	
		{
			title:"Direita",			
			colors:[
				"#C20000", "#095FA3", "#B7B7B7"
			],
			actions:[]
		}
	]
}

const PanelMotion  = {
	panel: "Edição de Vídeo (Motion)",
	title: "",
	configurationCase: 0,
	permission: false,
	specialCharts:[],
	leftActions:[],
	rightActions:[]
}

export const Panels = [PanelBasic,PanelTelestrator,PainelCategoriaCanal,PanelCustom,PanelMotion];
