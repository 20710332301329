import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
    onAuthStateChanged, 
    signOut as firebaseSignOut, 
    signInWithGoogle as firebaseSignInWithGoogle ,
    signInWithEmail as firebaseSignInWithEmail,
    createUserWithEmail as firebaseCreateUserWithEmail,
    sendPasswordReset as sendPasswordResetEmail,
    sendVerification as sendVerificationEmail,
} from '../lib/firebase/auth';

import {
    getUserPermission,
} from '../lib/firebase/database'

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permission, setPermission ] = useState(false);
    const [permissionDate, setPermissionDate ] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(user => {            
            setUser(user);
            setLoading(false);
            if(user?.uid){
                getUserPermission(user.uid,setPermissionDate);
            }
        });

        return () => unsubscribe();

    }, []);

    useEffect(() => {
        setPermission(checkExpiration(permissionDate));        
    },[permissionDate]);

    const signInWithGoogle = async () => {
        await firebaseSignInWithGoogle();
    };

    const signOut = async () => {
        await firebaseSignOut();
    };

    const signInWithEmail = async (email, password, setError) => {
        await firebaseSignInWithEmail(email, password, setError);
    };

    const createUserWithEmail = async (name, email, password, setError) =>{
        await firebaseCreateUserWithEmail(name, email, password, setError);
    }

    const sendPasswordReset = async(email) => {
        await sendPasswordResetEmail(email);
    }

    const sendVerification = async(email) =>{
        await sendVerificationEmail(email);
    }

    const value = {
        user,
        loading,
        permission,
        signInWithGoogle,
        signOut,
        signInWithEmail,
        createUserWithEmail,
        sendPasswordReset,
        sendVerification
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

function checkExpiration(dataString) {
    
    if(!dataString){
        return false;
    }
    
    if(dataString==="never"){
        return true;
    }
    
    const data = new Date(dataString);    
    data.setHours(0, 0, 0, 0);

    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);

    const ontem = new Date(hoje);
    ontem.setDate(hoje.getDate() - 2);
    
    return data >= ontem;
}