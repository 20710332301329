export function checkExpiration(dataString) {
    if(!dataString){
        return false;
    }
    if(dataString==="never"){
        return true;
    }
    const data = new Date(dataString);    
    data.setHours(0, 0, 0, 0);
    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);
    const ontem = new Date(hoje);
    ontem.setDate(hoje.getDate() - 2);
    
    return data >= ontem;
}

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

export function stringAvatar(name,size) {
    return {
        sx: {
            width: size,
            height: size,
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
    };
}

export function friendlyDate(data) {    
    var partes = data.split("-");       
    var ano = partes[0];
    var mes = partes[1];
    var dia = partes[2];
    var novaData = dia + "/" + mes + "/" + ano;
    return novaData;    
}

export function numberToBRL(numero) {
    const numeroFormatado = numero.toFixed(2);
    const partes = numeroFormatado.split('.');
    const parteInteiraFormatada = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return 'R$ ' + parteInteiraFormatada + ',' + partes[1];
}

export function numberToUSD(numero) {
    const numeroFormatado = numero.toFixed(2);
    const partes = numeroFormatado.split('.');
    const parteInteiraFormatada = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return 'US$ ' + parteInteiraFormatada + '.' + partes[1];
}

export const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export async function pausarPorSegundos(segundos) {
    return new Promise(resolve => setTimeout(resolve, segundos * 1000));
}	

export function FloatToTime(value) {
    if(value > 0){
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60);
        let formattedTime;
        if(hours>0){
            formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }else{
            formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
        return formattedTime;
    }else{
        return '00:00';
    }
}