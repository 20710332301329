import { get, ref, update } from "firebase/database";
import { database } from "./firebase";

export const getUserPermission = async (userId,setPermissionDate) => {    
    await get(ref(database,`/users/${userId}/expiration_date`) )
        .then((snapshot)=>{
            if (snapshot.exists()){
                setPermissionDate(snapshot.val());
            }
        }).catch((error)=>{
            console.log(error);
        })
}

export const updateActionNode = async (userId,analiseUid,nodePath,newData) => {
    const updates = {};
    updates[`/users/${userId}/analises_dados/${analiseUid}${nodePath}/`] = newData;			
    const dbRef = ref(database);
    await update(dbRef, updates)
        .then(()=>{
            
        })
        .catch((error)=>{
            console.log(error);
        });
}

export const getUserAnalysis = async (userId, analysisUid) => {
    try {
        const snapshot = await get(ref(database, `/users/${userId}/analises_dados/${analysisUid}`));
        if (snapshot.exists()) {
            const data = snapshot.val();
            return data; 
        } else {
            console.log("Análise não encontrada!");
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};